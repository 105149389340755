// Init Lightboxes
// uses: https://github.com/electerious/basicLightbox
(function (window) {
  function initLightboxes(selector = '[data-lightbox-show-pdf]') {
    var dialogs = document.querySelectorAll(selector);

    dialogs.forEach(function (trigger) {
      var source = document.querySelector(trigger.dataset.lightboxShow);

      if (selector == '[data-lightbox-show-pdf]') {
        source = document.querySelector(trigger.dataset.lightboxShowPdf);
      }

      if (source) {
        var lightbox = basicLightbox.create(source);
        var closeTriggers = lightbox
          .element()
          .querySelectorAll('[data-lightbox-close]');
        trigger.onclick = function (e) {
          e.preventDefault();
          lightbox.show();
        };
        closeTriggers.forEach(function (close) {
          close.onclick = lightbox.close;
        });
      }
    });
  }

  function initPDFLightboxes() {
    'use strict';
    if (navigator.pdfViewerEnabled) {
      initLightboxes('[data-lightbox-show-pdf]');
    }
  }

  initPDFLightboxes();
})(this);
